import originalsPhoto from '../images/dev/originals.png';
import voicesPhoto from '../images/dev/voices.png';

const Voices = () => {
  return (
    <>
      <div className='mx-5 mt-15 mb-5 lg:flex lg:flex-col lg:justify-between'>
        <div className='lg:mx-8 lg:pt-[3.25rem] flex flex-col gap-[3.125rem] items-center lg:flex lg:flex-col lg:h-full lg:justify-between'>
          <h1 className='font-bold text-xl tracking-[0.5em] uppercase bg-clip-text text-transparent bg-hero-gradient mb-[3.125rem] lg:self-start'>
            Discover
          </h1>
          <div className='flex flex-col gap-[3.125rem] items-center lg:flex-row lg:gap-8 lg:justify-center lg:items-start'>
            <div className='w-[15.5rem] lg:w-[19.75rem] flex flex-col items-center pb-5 px-5 pt-[5.75rem] lg:pt-[7.5rem] gap-5 bg-gradient-to-b from-[#D6D6D6]/50 to-[#EBEBEB]/0 rounded relative mb-[3.125rem] lg:mb-0'>
              <img
                src={originalsPhoto}
                alt='originals photo'
                className='absolute top-0 max-w-[9.625rem] lg:max-w-[12.875rem] lg:-mt-[6.4375rem] -mt-[4.8125rem]'
              />
              <div className='flex flex-col gap-1 items-center'>
                <span className='font-bold text-spreen-green text-[2.75rem] lg:text-[3.5rem] leading-none'>
                  Originals
                </span>
                <span className='text-sm text-center lg:text-base'>
                  Valuable messages <br /> inmortalized as NFTs
                </span>
              </div>
              <button className='py-2 px-4 text-white font-semibold text-sm bg-spreen-green rounded'>
                Explore
              </button>
            </div>
            <div className='w-[15.5rem] lg:w-[19.75rem] flex flex-col items-center pb-5 px-5 pt-[5.75rem] lg:pt-[7.5rem] gap-5 bg-gradient-to-b from-[#D6D6D6]/50 to-[#EBEBEB]/0 rounded relative'>
              <img
                src={voicesPhoto}
                alt='voices photo'
                className='absolute top-0 max-w-[9.625rem] lg:max-w-[12.875rem] lg:-mt-[6.4375rem] -mt-[4.8125rem]'
              />

              <div className='flex flex-col gap-1 items-center'>
                <span className='font-bold text-light-gray text-[2.75rem]  lg:text-[3.5rem]  leading-none'>
                  Voices
                </span>
                <span className='text-sm text-center text-light-gray lg:text-base'>
                  Support decentralized <br /> communicative actions <br />{' '}
                  around the globe
                </span>
              </div>
              <button className='py-2 px-4 text-white font-semibold text-sm bg-light-gray rounded'>
                Coming soon
              </button>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Voices;
