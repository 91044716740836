import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import ClipboardJS from 'clipboard';
import pohIcon from '../images/icons/poh-icon.svg';
import { useOutletContext } from 'react-router-dom';
import walletIllustration from '../images/illustrations/wallet.png';

import TokenDisclosure from '../components/TokenDisclosure.js';
import NFTSDisclosure from '../components/NFTSDisclosure.js';
import POAPDisclosure from '../components/POAPDisclosure.js';
import ENSDisclosure from '../components/ENSDisclosure.js';
import VoicesDisclosure from '../components/VoicesDisclosure.js';
import LandsDisclosure from '../components/LandsDisclosure.js';
import GamingDisclosure from '../components/GamingDisclosure.js';
import Avatar from 'boring-avatars';

const Home = () => {
  const [defaultAccount, userBalance, ensName, provider, verifiedHuman] =
    useOutletContext();
  const [copied, setCopied] = useState(false);
  let clipboard = new ClipboardJS('.group');

  useEffect(() => {
    let timeout;
    clipboard.on('success', function (e) {
      setCopied(true);
      e.clearSelection();
      timeout = setTimeout(() => {
        setCopied(false);
      }, 500);
    });

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div
      className={`${
        defaultAccount === '' && 'lg:flex lg:justify-center lg:items-center'
      } m-5`}
    >
      <div className='lg:mx-8 lg:pt-[3.25rem] lg:pb-10'>
        {defaultAccount !== '' ? (
          <>
            <div className='flex gap-3 items-center justify-between mb-[1.875rem]'>
              <div className='gap-3 flex items-center'>
                <Avatar
                  size={76}
                  name={defaultAccount}
                  variant='marble'
                  color={[
                    '#263449',
                    '#415398',
                    '#DF3CB1',
                    '#EBEBEB',
                    '#05F182',
                  ]}
                />

                <div className='flex flex-col'>
                  {verifiedHuman && (
                    <div className='w-fit border-[0.5px] border-[#FF9900] rounded-sm flex items-center gap-1 px-2 py-1'>
                      <img src={pohIcon} alt='poh icon' />
                      <span className='text-[0.6825rem] leading-[0.831875rem] bg-clip-text text-transparent bg-gradient-to-r from-[#FF9900] to-[#FFC700]'>
                        Verified Human
                      </span>
                    </div>
                  )}
                  <div className='flex items-center gap-2 '>
                    <span className='font-semibold text-[1.375rem] lg:text-2xl'>
                      {ensName !== null
                        ? ensName
                        : defaultAccount.slice(0, 5) +
                          '...' +
                          defaultAccount.slice(-4)}
                    </span>

                    <button
                      data-clipboard-text={defaultAccount}
                      className='group'
                    >
                      {!copied && (
                        <Icon
                          icon='mdi:content-copy'
                          className='text-dark-platinum group-hover:text-[#263449]'
                        />
                      )}
                      {copied && (
                        <Icon
                          icon='teenyicons:tick-circle-outline'
                          color='#263449'
                        />
                      )}
                    </button>

                    {/* CONFIGURATION USER NOT ENABLED YET */}
                    {/* <Icon
                  icon='mdi:account-cog'
                  color='#EBEBEB'
                  className='lg:hidden'
                /> */}
                  </div>
                  {/* BIO WHEN DB IS READY TO SAVE DATA FOR USER */}
                  {/* <span className='text-sm lg:text-base'>
                Artist based in Argentina 👾
              </span> */}
                </div>
              </div>
              <button className='cursor-not-allowed hidden lg:flex self-end px-2 py-1 border-[0.5px] border-light-gray rounded-sm items-center gap-1 text-light-gray text-xs'>
                Filter
                <Icon icon='charm:menu-kebab' color='#D6D6D6' />
              </button>
            </div>
            <div className='flex justify-end mt-4 lg:hidden'>
              <button className='cursor-not-allowed px-2 py-1 border-[0.5px] border-light-gray rounded-sm  flex items-center gap-1 text-light-gray text-xs'>
                Filter
                <Icon icon='charm:menu-kebab' color='#D6D6D6' />
              </button>
            </div>
            <div className='flex flex-col gap-3 mt-3 lg:mt-0 lg:grid lg:grid-cols-3'>
              <TokenDisclosure
                userBalance={userBalance}
                defaultAccount={defaultAccount}
              />
              <NFTSDisclosure defaultAccount={defaultAccount} />
              <POAPDisclosure defaultAccount={defaultAccount} />
              <ENSDisclosure defaultAccount={defaultAccount} />
              <VoicesDisclosure defaultAccount={defaultAccount} />
              <LandsDisclosure />
              <GamingDisclosure />
            </div>
          </>
        ) : (
          <div className='flex flex-col items-center gap-3 max-w-xs lg:max-w-sm mx-auto'>
            <img src={walletIllustration} alt='wallet illustration' />
            <span className='font-medium text-sm text-dark-platinum'>
              Wallet not conected!
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
