import { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import ensIcon from '../images/icons/ens.svg';

const Ens = () => {
  let location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  let defaultAccount = searchParams.get('address');
  const [ens, setEns] = useState([]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var graphql = JSON.stringify({
      query:
        'query ($address: ID!)\n{\n  accounts(where: {id: $address}) {        \n     registrations {\n      domain {\n        name\n      }\n    }\n  }\n}',
      variables: {
        address: defaultAccount.toLowerCase(),
      },
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
      redirect: 'follow',
    };

    if (location !== null) {
      setEns(location.state);
    } else {
      async function fetchData() {
        await fetch(
          'https://api.thegraph.com/subgraphs/name/ensdomains/ens',
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => setEns(result.data.accounts[0].registrations))
          .catch((error) => console.log('error', error));
      }
      fetchData();
    }
  }, [defaultAccount]);

  return (
    <div className='m-5'>
      <div className='lg:mx-8 lg:pt-[3.25rem] lg:pb-10'>
        {defaultAccount !== null && defaultAccount !== '' && (
          <>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-2.5 sm:gap-5'>
              {ens.map(({ domain }) => (
                <div
                  key={domain.name}
                  className='w-full flex gap-2 items-center bg-ens-blue rounded p-2'
                >
                  <img src={ensIcon} alt='ens icon' />
                  <span className='text-white font-semibold text-sm truncate'>
                    {domain.name}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Ens;
