import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ethers } from 'ethers';
// import { supabase } from './supabaseClient.js';
import Navbar from './components/Navbar.js';
import BottomNav from './components/BottomNav.js';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
const alchemyKey = process.env.REACT_APP_ALCHEMY_API_KEY;
const App = () => {
  let location = useLocation();

  // GOOGLE ANALYTICS
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${location.pathname + location.search}`,
    });
  }, [location]);

  const [defaultAccount, setDefaultAccount] = useState('');
  const [userBalance, setUserBalance] = useState(0);
  const [ensName, setEnsName] = useState(null);
  const [verifiedHuman, setVerifiedHuman] = useState(false);

  let provider = new ethers.providers.AlchemyProvider('homestead', alchemyKey);

  let providerEthersjs;

  if (window.ethereum) {
    providerEthersjs = new ethers.providers.Web3Provider(
      window.ethereum,
      'any'
    );
  }

  const verifyHuman = async (address) => {
    await fetch(`https://api.poh.dev/profiles/${address}`, {
      method: 'GET',
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then(
        (result) => result.status === 'REGISTERED' && setVerifiedHuman(true)
      )
      .catch((error) => console.log('error', error));
  };

  const connectwalletHandler = () => {
    if (window.ethereum) {
      providerEthersjs
        .send('eth_requestAccounts', [])
        .then(async () => {
          await accountChangedHandler(providerEthersjs.getSigner());
          ReactGA.event({
            category: 'Connection',
            action: 'connect',
          });
        })
        .catch((error) => console.log(error));
    } else {
      alert('Install metamask');
    }
  };
  const accountChangedHandler = async (newAccount) => {
    const address = await newAccount.getAddress();
    setDefaultAccount(address);
    const balance = await newAccount.getBalance();
    setUserBalance(ethers.utils.formatEther(balance));
    const ethereumName = await provider.lookupAddress(address);
    setEnsName(ethereumName);
    verifyHuman(address);
  };

  return (
    <>
      <div className='lg:max-w-[1152px] lg:mx-auto lg:relative lg:grid lg:grid-cols-[10rem_1fr]'>
        <Navbar
          defaultAccount={defaultAccount}
          ensName={ensName}
          connect={connectwalletHandler}
        />
        <Outlet
          context={[
            defaultAccount,
            userBalance,
            ensName,
            provider,
            verifiedHuman,
          ]}
        />
      </div>
      <BottomNav />
    </>
  );
};

export default App;
