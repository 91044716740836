import favoritesIllustration from '../images/illustrations/favorites.png';
const Favorites = () => {
  return (
    <>
      <div className='m-5'>
        <div className='lg:h-full lg:justify-center flex flex-col items-center gap-3 max-w-xs lg:max-w-sm mx-auto mt-4 lg:mt-0'>
          <img src={favoritesIllustration} alt='search illustration' />
          <span className='font-medium text-sm text-dark-platinum'>
            Your favourite wallets will appear here!
          </span>
        </div>
      </div>
    </>
  );
};

export default Favorites;
