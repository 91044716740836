import { useEffect, useState } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
const alchemyKeyPolygon = process.env.REACT_APP_ALCHEMY_API_KEY_POLYGON;

const Voicesnfts = () => {
  let location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  let defaultAccount = searchParams.get('address');

  const [NFTS, setNFTS] = useState([]);

  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    if (location.state !== null) {
      setNFTS(location.state);
    } else {
      async function fetchData() {
        await fetch(
          `https://polygon-mainnet.g.alchemy.com/v2/${alchemyKeyPolygon}/getNFTs/?owner=${defaultAccount}&contractAddresses[]=0xa02d547512bb90002807499f05495fe9c4c3943f`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setNFTS([]);
            result.ownedNfts.map((result, index) =>
              fetch(
                `https://eth-mainnet.g.alchemy.com/v2/${alchemyKeyPolygon}/getNFTMetadata?contractAddress=${result.contract.address}&tokenId=${result.id.tokenId}`,
                requestOptions
              )
                .then((response) => response.json())
                .then((result) => {
                  if (result.metadata.image !== undefined) {
                    if (result.metadata.image.startsWith('ipfs://ipfs')) {
                      const newURL = result.metadata.image
                        .split('/')
                        .slice(2)
                        .join('/');
                      const resultObj = {
                        index: index,
                        contractAddress: result.contract.address,
                        tokenId: result.id.tokenId,
                        image: 'https://ipfs.io/'.concat(newURL),
                        name: result.metadata.name,
                        description: result.metadata.description,
                      };
                      setNFTS((element) => [...element, resultObj]);
                    } else if (result.metadata.image.startsWith('ipfs://')) {
                      const newURL = result.metadata.image
                        .split('/')
                        .slice(2)
                        .join('/');
                      const resultObj = {
                        index: index,
                        contractAddress: result.contract.address,
                        tokenId: result.id.tokenId,
                        image: 'https://ipfs.io/ipfs/'.concat(newURL),
                        name: result.metadata.name,
                        description: result.metadata.description,
                      };
                      setNFTS((element) => [...element, resultObj]);
                    } else {
                      const resultObj = {
                        index: index,
                        contractAddress: result.contract.address,
                        tokenId: result.id.tokenId,
                        image: result.metadata.image,
                        name: result.metadata.name,
                        description: result.metadata.description,
                      };
                      setNFTS((element) => [...element, resultObj]);
                    }
                  } else if (result.metadata.image_url !== undefined) {
                    if (result.metadata.image_url.startsWith('ipfs://ipfs')) {
                      const newURL = result.metadata.image_url
                        .split('/')
                        .slice(2)
                        .join('/');
                      const resultObj = {
                        index: index,
                        contractAddress: result.contract.address,
                        tokenId: result.id.tokenId,
                        image: 'https://ipfs.io/'.concat(newURL),
                        name: result.metadata.name,
                        description: result.metadata.description,
                      };
                      setNFTS((element) => [...element, resultObj]);
                    } else if (
                      result.metadata.image_url.startsWith('ipfs://')
                    ) {
                      const newURL = result.metadata.image_url
                        .split('/')
                        .slice(2)
                        .join('/');
                      const resultObj = {
                        index: index,
                        contractAddress: result.contract.address,
                        tokenId: result.id.tokenId,
                        image: 'https://ipfs.io/ipfs/'.concat(newURL),
                        name: result.metadata.name,
                        description: result.metadata.description,
                      };
                      setNFTS((element) => [...element, resultObj]);
                    } else {
                      const resultObj = {
                        index: index,
                        contractAddress: result.contract.address,
                        tokenId: result.id.tokenId,
                        image: result.metadata.image_url,
                        name: result.metadata.name,
                        description: result.metadata.description,
                      };
                      setNFTS((element) => [...element, resultObj]);
                    }
                  }
                })
                .catch((error) => console.log('error', error))
            );
          })
          .catch((error) => console.log('error', error));
      }
      fetchData();
    }
  }, [defaultAccount, location.state]);
  const [showincase, setshowincase] = useState([false, -1]);

  return (
    <div className='m-5 '>
      <div className='lg:mx-8 lg:pt-[3.25rem] lg:pb-10'>
        {defaultAccount !== null && defaultAccount !== '' && (
          <>
            <div className='grid grid-cols-3 gap-4 sm:grid-cols-4 lg:grid-cols-5'>
              {NFTS !== null &&
                NFTS.sort((a, b) => {
                  return a.index - b.index;
                }).map((token, index) => (
                  <Link
                    to={`/voicesnft/${token.contractAddress}?tokenId=${token.tokenId}`}
                    state={token}
                    key={index}
                    className='flex flex-col gap-2 border-[0.5px] border-dark-platinum rounded'
                  >
                    <div className='relative object-cover rounded-t aspect-square bg-hero-gradient'>
                      {showincase[0] && showincase[1] === index && (
                        <div className='absolute bottom-0 right-0 rounded-t bg-[#ececec]/10 backdrop-blur-md z-10 h-[101%] w-full flex flex-col justify-center items-center gap-0.5'>
                          <span className='font-semibold text-xs sm:text-sm text-white'>
                            Unable to display <br />
                            Click to see more
                          </span>
                        </div>
                      )}
                      <img
                        src={token.image}
                        onError={() => {
                          setshowincase([true, index]);
                        }}
                        alt={('nft', index)}
                        loading='lazy'
                        className='object-cover rounded-t aspect-square bg-hero-gradient'
                      />
                    </div>

                    <span className='font-semibold text-xs mx-3 mb-2 truncate'>
                      {token.name}
                    </span>
                  </Link>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Voicesnfts;
