import { Icon } from '@iconify/react';
import { NavLink } from 'react-router-dom';

import logo from '../images/brand/logo-bottom-nav.svg';

const BottomNav = () => {
  return (
    <>
      <div className='pt-20 lg:pt-0'></div>
      <div className='bg-dark-platinum grid grid-cols-4 w-full lg:hidden fixed bottom-0 overflow-hidden z-50'>
        <NavLink
          to='/'
          className={({ isActive }) =>
            `${
              isActive ? 'opacity-100' : 'opacity-20'
            } flex flex-col justify-center items-center text-[0.625rem] leading-3 font-medium gap-1 pt-3 pb-2`
          }
        >
          <Icon icon='mdi:account' color='#263449' width='24' />
          Profile
        </NavLink>
        <NavLink
          to='/search'
          className={({ isActive }) =>
            `${
              isActive ? 'opacity-100' : 'opacity-20'
            } flex flex-col justify-center items-center text-[0.625rem] leading-3 font-medium gap-1 pt-3 pb-2`
          }
        >
          <Icon icon='mdi:account-search' color='#263449' width='24' />
          Search
        </NavLink>
        <NavLink
          to='/favorites'
          className={({ isActive }) =>
            `${
              isActive ? 'opacity-100' : 'opacity-20'
            } flex flex-col justify-center items-center text-[0.625rem] leading-3 font-medium gap-1 pt-3 pb-2`
          }
        >
          <Icon icon='mdi:heart' color='#263449' width='24' />
          Favorites
        </NavLink>
        <NavLink
          to='/voices'
          className={({ isActive }) =>
            `${
              isActive ? 'opacity-100' : 'opacity-20'
            } flex flex-col justify-center items-center text-[0.625rem] leading-3 font-medium gap-1 pt-3 pb-2`
          }
        >
          <img src={logo} alt='logo' className='w-6' />
          Voices
        </NavLink>
      </div>
    </>
  );
};

export default BottomNav;
