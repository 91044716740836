import { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

const alchemyKey = process.env.REACT_APP_ALCHEMY_API_KEY;
const NftTokenId = () => {
  let location = useLocation();
  let contractAddress = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let tokenId = searchParams.get('tokenId');

  const [nftContent, setNftContent] = useState();

  useEffect(() => {
    if (location.state !== null) {
      setNftContent(location.state);
    } else {
      let requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };
      async function fetchNftContent() {
        await fetch(
          `https://eth-mainnet.g.alchemy.com/v2/${alchemyKey}/getNFTMetadata?contractAddress=${contractAddress}&tokenId=${tokenId}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.metadata.image !== undefined) {
              if (result.metadata.image.startsWith('ipfs://ipfs')) {
                const newURL = result.metadata.image
                  .split('/')
                  .slice(2)
                  .join('/');
                const resultObj = {
                  contractAddress: result.contract.address,
                  tokenId: result.id.tokenId,
                  image: 'https://ipfs.io/'.concat(newURL),
                  name: result.metadata.name,
                  description: result.metadata.description,
                };
                setNftContent(resultObj);
              } else if (result.metadata.image.startsWith('ipfs://')) {
                const newURL = result.metadata.image
                  .split('/')
                  .slice(2)
                  .join('/');
                const resultObj = {
                  contractAddress: result.contract.address,
                  tokenId: result.id.tokenId,
                  image: 'https://ipfs.io/ipfs/'.concat(newURL),
                  name: result.metadata.name,
                  description: result.metadata.description,
                };
                setNftContent(resultObj);
              } else {
                const resultObj = {
                  contractAddress: result.contract.address,
                  tokenId: result.id.tokenId,
                  image: result.metadata.image,
                  name: result.metadata.name,
                  description: result.metadata.description,
                };
                setNftContent(resultObj);
              }
            } else if (result.metadata.image_url !== undefined) {
              if (result.metadata.image_url.startsWith('ipfs://ipfs')) {
                const newURL = result.metadata.image_url
                  .split('/')
                  .slice(2)
                  .join('/');
                const resultObj = {
                  contractAddress: result.contract.address,
                  tokenId: result.id.tokenId,
                  image: 'https://ipfs.io/'.concat(newURL),
                  name: result.metadata.name,
                  description: result.metadata.description,
                };
                setNftContent(resultObj);
              } else if (result.metadata.image_url.startsWith('ipfs://')) {
                const newURL = result.metadata.image_url
                  .split('/')
                  .slice(2)
                  .join('/');
                const resultObj = {
                  contractAddress: result.contract.address,
                  tokenId: result.id.tokenId,
                  image: 'https://ipfs.io/ipfs/'.concat(newURL),
                  name: result.metadata.name,
                  description: result.metadata.description,
                };
                setNftContent(resultObj);
              } else {
                const resultObj = {
                  contractAddress: result.contract.address,
                  tokenId: result.id.tokenId,
                  image: result.metadata.image_url,
                  name: result.metadata.name,
                  description: result.metadata.description,
                };
                setNftContent(resultObj);
              }
            }
          })
          .catch((error) => console.log('error', error));
      }
      fetchNftContent();
    }
  }, [tokenId, contractAddress, location.state]);

  return (
    <div className='m-5 '>
      <div className='lg:mx-8 lg:pt-[3.25rem] lg:pb-10'>
        {nftContent !== undefined && nftContent !== null && (
          <div className='flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-around'>
            <div className='bg-[#F6F6F6] rounded aspect-square flex items-center justify-center max-w-[28rem] self-center'>
              <img
                src={nftContent.image}
                alt='nft example'
                className='object-cover rounded aspect-square'
              />
            </div>
            <div className='flex flex-col gap-4 sm:w-1/2 sm:justify-between sm:h-fill'>
              <span className='font-bold text-xl sm:font-bold sm:text-[2rem] sm:leading-[2.625rem]'>
                {nftContent.name}
              </span>
              <div className='flex flex-col'>
                <span className='text-xs text-light-gray border-b-[0.5px] pb-1 mb-1 border-light-gray'>
                  Description
                </span>
                <span className='text-sm'>{nftContent.description}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NftTokenId;
