import { useState, useEffect } from 'react';
import { ethers, BigNumber } from 'ethers';
import { Icon } from '@iconify/react';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';
import defaultIcon from '../images/icons/empty-token.webp';

const alchemyKey = process.env.REACT_APP_ALCHEMY_API_KEY;
const TokenDisclosure = ({ userBalance, defaultAccount }) => {
  const [tokens, setTokens] = useState([]);
  useEffect(() => {
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    let raw = JSON.stringify({
      jsonrpc: '2.0',
      method: 'alchemy_getTokenBalances',
      params: [`${defaultAccount}`, 'DEFAULT_TOKENS'],
      id: '42',
    });

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    let rawList = JSON.stringify({
      jsonrpc: '2.0',
      method: 'alchemy_getTokenBalances',
      params: [
        `${defaultAccount}`,
        ['0xb624fde1a972b1c89ec1dad691442d5e8e891469'],
      ],
      id: '42',
    });

    let requestOptionsList = {
      method: 'POST',
      headers: myHeaders,
      body: rawList,
      redirect: 'follow',
    };

    const fetchCertainTokens = async () => {
      await fetch(
        `https://eth-mainnet.alchemyapi.io/v2/${alchemyKey}`,
        requestOptionsList
      )
        .then((response) => response.json())
        .then((result) => {
          result.result.tokenBalances
            .filter((item) => Number(item.tokenBalance) > 0)
            .map((item, index) => {
              fetch(`https://eth-mainnet.alchemyapi.io/v2/${alchemyKey}`, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                  jsonrpc: '2.0',
                  method: 'alchemy_getTokenMetadata',
                  params: [`${item.contractAddress}`],
                  id: 1,
                }),
                redirect: 'follow',
              })
                .then((response) => response.json())
                .then((result) => {
                  const resultObj = {
                    index: index,
                    logo:
                      result.result.logo === null
                        ? defaultIcon
                        : result.result.logo,
                    name: result.result.name,
                    symbol: result.result.symbol,
                    quantity: ethers.utils.formatUnits(
                      BigNumber.from(item.tokenBalance),
                      result.result.decimals
                    ),
                  };
                  setTokens((element) => [...element, resultObj]);
                })
                .catch((error) => console.log('error', error));
            });
        })
        .catch((error) => console.log('error', error));
    };

    async function fetchData() {
      await fetch(
        `https://eth-mainnet.alchemyapi.io/v2/${alchemyKey}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setTokens([]);
          result.result.tokenBalances
            .filter((item) => Number(item.tokenBalance) > 0)
            .map((item, index) => {
              fetch(`https://eth-mainnet.alchemyapi.io/v2/${alchemyKey}`, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                  jsonrpc: '2.0',
                  method: 'alchemy_getTokenMetadata',
                  params: [`${item.contractAddress}`],
                  id: 1,
                }),
                redirect: 'follow',
              })
                .then((response) => response.json())
                .then((result) => {
                  const resultObj = {
                    index: index,
                    logo: result.result.logo,
                    name: result.result.name,
                    symbol: result.result.symbol,
                    quantity: ethers.utils.formatUnits(
                      BigNumber.from(item.tokenBalance),
                      result.result.decimals
                    ),
                  };
                  setTokens((element) => [...element, resultObj]);
                })
                .catch((error) => console.log('error', error));
            });
        })
        .catch((error) => console.log('error', error));
    }
    fetchData();
    fetchCertainTokens();
  }, [defaultAccount]);

  return (
    <>
      <div className='lg:flex gap-4 flex-col hidden bg-platinum col-end-4 col-span-1 row-start-1 row-end-5 rounded p-4'>
        <div className='flex justify-between items-center'>
          <span className='tracking-[0.2em] font-bold'>TOKENS</span>
          <span className='text-sm tracking-[0.2em] font-light'>ERC 20</span>
        </div>
        <div className='p-4 bg-btn-gradient rounded flex justify-center items-center flex-col'>
          <span className='text-2xl font-bold text-white'>
            {parseFloat(userBalance).toFixed(3)}
          </span>
          <span className='text-[0.625rem] leading-3 text-white'>
            ETH - Ethereum
          </span>
        </div>
        <div className='flex justify-between px-1 pb-1 border-b-[0.5px] border-[#D6D6D6] w-full text-[0.5rem] text-light-gray uppercase tracking-[0.1em] leading-[0.625rem]'>
          <span>Name</span>
          <span>Quantity</span>
        </div>
        <div className='space-y-4'>
          {tokens
            .sort((a, b) => {
              return a.index - b.index;
            })
            .map((token) => (
              <div key={token.symbol} className='flex gap-1 justify-between'>
                <div className='flex gap-1 items-center'>
                  <img
                    src={token.logo}
                    alt={token.index}
                    className='w-8 h-8 rounded-full'
                  />
                  <div className='flex flex-col'>
                    <span className='text-xs font-semibold'>{token.name}</span>
                    <span className='text-[0.625rem] leading-[0.75rem] text-silver uppercase'>
                      {token.symbol}
                    </span>
                  </div>
                </div>
                <span className='text-xs'>
                  {parseFloat(token.quantity).toLocaleString()}
                </span>
              </div>
            ))}
        </div>
        <Link
          to='/learn'
          className='text-blue-link text-[0.625rem] leading-3 w-full text-right flex items-center justify-end gap-1'
        >
          <span>
            What’s a <span className='font-semibold'> Token?</span>{' '}
          </span>
          <Icon
            icon='ant-design:info-circle-outlined'
            className='text-blue-link inline'
          />
        </Link>
      </div>
      <div className='lg:hidden'>
        <Disclosure defaultOpen={tokens.length !== 0}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open ? 'rounded-t' : 'rounded'
                } flex justify-between items-center w-full p-3 rounded-t focus:outline-none bg-platinum text-sm`}
              >
                <div className='gap-2 flex items-center'>
                  <span className='tracking-[0.2em] font-bold'>TOKENS</span>
                  <span className='text-xs tracking-[0.2em] font-light'>
                    ERC 20
                  </span>
                </div>
                <div className='flex items-center gap-2'>
                  {/* <span className='text-xs font-semibold'>123K</span> */}
                  <Icon
                    icon='akar-icons:chevron-up'
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-4  text-charcoal`}
                  />
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className='border-[0.5px] border-t-0 p-4 rounded-b border-platinum'>
                <div className='p-4 bg-btn-gradient rounded flex justify-center items-center flex-col mb-3'>
                  <span className='text-2xl font-bold text-white'>
                    {parseFloat(userBalance).toFixed(3)}
                  </span>
                  <span className='text-[0.625rem] leading-3 text-white'>
                    ETH - Ethereum
                  </span>
                </div>
                <div className='flex justify-between px-1 pb-1 border-b-[0.5px] border-[#D6D6D6] w-full text-[0.5rem] text-light-gray uppercase tracking-[0.1em] leading-[0.625rem]'>
                  <span>Name</span>
                  <span>Quantity</span>
                </div>
                <div className='mt-3 space-y-3'>
                  {tokens.map((token) => (
                    <div key={token.symbol} className='flex justify-between'>
                      <div className='flex gap-1 items-center'>
                        <img
                          src={token.logo}
                          alt={token.symbol}
                          className='h-8 w-8 rounded-full'
                        />
                        <div className='flex flex-col'>
                          <span className='text-xs font-semibold'>
                            {token.name}
                          </span>
                          <span className='text-[0.625rem] leading-[0.75rem] text-silver uppercase'>
                            {token.symbol}
                          </span>
                        </div>
                      </div>
                      <span className='text-xs truncate'>
                        {parseFloat(token.quantity).toLocaleString()}
                      </span>
                    </div>
                  ))}
                </div>
                <button className='w-full bg-platinum rounded-sm p-2 text-[0.625rem] leading-3 mt-3'>
                  View all
                </button>
                <Link
                  to='/learn'
                  className=' text-blue-link text-[0.625rem] leading-3 mt-3 w-full text-right flex items-center justify-end gap-1'
                >
                  <span>
                    What’s a <span className='font-semibold'> Token?</span>{' '}
                  </span>
                  <Icon
                    icon='ant-design:info-circle-outlined'
                    className='text-blue-link inline'
                  />
                </Link>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};

export default TokenDisclosure;
