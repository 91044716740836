import { Icon } from '@iconify/react';
import { Disclosure } from '@headlessui/react';
import ensIcon from '../images/icons/ens.svg';
import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react';

const ENSDisclosure = ({ defaultAccount }) => {
  const [ens, setEns] = useState([]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var graphql = JSON.stringify({
      query:
        'query ($address: ID!)\n{\n  accounts(where: {id: $address}) {        \n     registrations {\n      domain {\n        name\n      }\n    }\n  }\n}',
      variables: {
        address: defaultAccount.toLowerCase(),
      },
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
      redirect: 'follow',
    };
    async function fetchData() {
      await fetch(
        'https://api.thegraph.com/subgraphs/name/ensdomains/ens',
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => setEns(result.data.accounts[0].registrations))
        .catch((error) => console.log('error', error));
    }
    fetchData();
  }, [defaultAccount]);

  return (
    <>
      <div className='hidden lg:flex flex-col  col-span-1 rounded'>
        <div className='flex justify-between items-center bg-platinum rounded-t py-3 px-4'>
          <div className='flex gap-2 items-center'>
            <span className='tracking-[0.2em] font-bold uppercase'>NAMES</span>
            <span className='text-xs tracking-[0.2em] font-light uppercase'>
              ENS
            </span>
          </div>
          <span className='tracking-[0.2em] font-bold'>{ens.length}</span>
        </div>
        <div className='p-4 border-[0.5px] border-platinum rounded-b flex flex-col justify-between gap-3 h-fill'>
          <div className='grid grid-cols-1 grid-rows-3 gap-2'>
            {ens !== [] &&
              ens.slice(0, 3).map(({ domain }) => (
                <Link
                  to={`/ens?address=${defaultAccount}`}
                  state={ens}
                  key={domain.name}
                  className='w-full flex gap-2 items-center bg-ens-blue rounded p-2'
                >
                  <img src={ensIcon} alt='ens icon' />
                  <span className='text-white font-semibold text-sm truncate'>
                    {domain.name}
                  </span>
                </Link>
              ))}
            {ens.length < 1 && (
              <div className='w-full flex gap-2 items-center bg-[#F6F6F6] rounded h-[2.625rem]'></div>
            )}
            {ens.length < 2 && (
              <div className='w-full flex gap-2 items-center bg-[#F6F6F6] rounded p-2'></div>
            )}
            {ens.length < 3 && (
              <div className='w-full flex gap-2 items-center bg-[#F6F6F6] rounded p-2'></div>
            )}
            {ens.length > 3 && (
              <Link
                to={`/ens?address=${defaultAccount}`}
                state={ens}
                className='w-full bg-platinum rounded-sm p-2 text-[0.625rem] leading-3'
              >
                View all
              </Link>
            )}
          </div>
          <Link
            to='/learn'
            className='text-blue-link text-[0.625rem] leading-3 w-full text-right flex items-center justify-end gap-1'
          >
            <span>
              What’s a <span className='font-semibold'> ENS Domanin?</span>{' '}
            </span>
            <Icon
              icon='ant-design:info-circle-outlined'
              className='text-blue-link inline'
            />
          </Link>
        </div>
      </div>
      <div className='lg:hidden'>
        {ens.length > 0 ? (
          <Disclosure defaultOpen='true'>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`${
                    open ? 'rounded-t' : 'rounded'
                  } flex justify-between items-center w-full p-3 rounded-t focus:outline-none bg-platinum text-sm`}
                >
                  <div className='gap-2 flex items-center'>
                    <span className='tracking-[0.2em] font-bold'>NAMES</span>
                    <span className='text-xs tracking-[0.2em] font-light'>
                      ENS
                    </span>
                  </div>
                  <div className='flex items-center gap-2'>
                    <span className='text-xs font-semibold'>{ens.length}</span>
                    <Icon
                      icon='akar-icons:chevron-up'
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-4  text-charcoal`}
                    />
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel className='border-[0.5px] border-t-0 p-4 rounded-b border-platinum'>
                  <div className='flex flex-col gap-2'>
                    {ens !== [] &&
                      ens.slice(0, 3).map(({ domain }) => (
                        <button
                          key={domain.name}
                          className='w-full flex gap-2 items-center bg-ens-blue rounded p-2'
                        >
                          <img src={ensIcon} alt='ens icon' />
                          <span className='text-white font-semibold text-sm truncate'>
                            {domain.name}
                          </span>
                        </button>
                      ))}
                  </div>
                  <button className='w-full bg-platinum rounded-sm p-2 text-[0.625rem] leading-3 mt-3'>
                    View all
                  </button>
                  <span className=' text-blue-link text-[0.625rem] leading-3 mt-3 w-full text-right flex items-center justify-end gap-1'>
                    <Link to='/learn'>
                      What’s an{' '}
                      <span className='font-semibold'> ENS Domanin?</span>{' '}
                    </Link>
                    <Icon
                      icon='ant-design:info-circle-outlined'
                      className='text-blue-link inline'
                    />
                  </span>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : (
          <button className='flex gap-1 justify-between items-center w-full p-3 rounded border-[0.5px] border-light-gray text-sm'>
            <div className='text-light-gray flex items-center gap-2 text-sm'>
              <span className='tracking-[0.2em] font-bold'>NAMES</span>
              <span className='font-light'>ENS</span>
            </div>
            <Link
              to='/learn'
              className=' text-blue-link text-[0.625rem] leading-3 flex items-center gap-1'
            >
              <span>
                What’s an <span className='font-semibold'> ENS Domanin?</span>{' '}
              </span>
              <Icon
                icon='ant-design:info-circle-outlined'
                className='text-blue-link inline'
              />
            </Link>
          </button>
        )}
      </div>
    </>
  );
};

export default ENSDisclosure;
