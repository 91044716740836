import { Icon } from '@iconify/react';
import { Disclosure, Transition } from '@headlessui/react';
import nftexample from '../images/dev/banner-example.jpeg';
import { Link } from 'react-router-dom';

const NFTS = [];

const LandsDisclosure = () => {
  return (
    <>
      <div className='hidden lg:flex flex-col  col-span-1 rounded'>
        <div className='flex justify-between items-center bg-platinum rounded-t py-3 px-4'>
          <div className='flex gap-2 items-center'>
            <span className='tracking-[0.2em] font-bold uppercase'>LANDS</span>
            <span className='text-xs tracking-[0.2em] font-light uppercase'>
              Metaverse
            </span>
          </div>
          <span className='tracking-[0.2em] font-bold'>{NFTS.length}</span>
        </div>
        <div className='p-4 border-[0.5px] border-platinum rounded-b flex flex-col gap-3'>
          <div className='grid grid-cols-4 gap-2'>
            {NFTS.map((token) => (
              <button
                key={token.index}
                className={`${
                  token.index === 1 && 'col-span-2 row-span-2'
                } bg-[#F6F6F6] rounded object-cover aspect-square relative`}
              >
                {token.index === 5 && (
                  <div className='absolute bottom-0 right-0 rounded bg-[#ececec]/10 backdrop-blur-md z-10 h-[101%] w-full flex flex-col justify-center items-center gap-0.5'>
                    <Icon
                      icon='healthicons:ui-menu-grid'
                      color='white'
                      className='mt-1'
                    />
                    <span className='text-white text-[0.625rem] leading-3'>
                      View all
                    </span>
                  </div>
                )}
                <img
                  src={nftexample}
                  alt='nft example'
                  className='object-cover rounded aspect-square'
                />
              </button>
            ))}
            {NFTS.length < 1 && (
              <div className='bg-[#F6F6F6] rounded object-cover aspect-square relative col-span-2 row-span-2'></div>
            )}

            {NFTS.length < 2 && (
              <div className='bg-[#F6F6F6] rounded object-cover aspect-square relative'></div>
            )}
            {NFTS.length < 3 && (
              <div className='bg-[#F6F6F6] rounded object-cover aspect-square relative'></div>
            )}
            {NFTS.length < 4 && (
              <div className='bg-[#F6F6F6] rounded object-cover aspect-square relative'></div>
            )}
            {NFTS.length < 5 && (
              <div className='bg-[#F6F6F6] rounded object-cover aspect-square relative'></div>
            )}
          </div>
          <Link
            to='/learn'
            className='text-blue-link text-[0.625rem] leading-3 w-full text-right flex items-center justify-end gap-1'
          >
            <span>
              What’s a <span className='font-semibold'> Land?</span>{' '}
            </span>
            <Icon
              icon='ant-design:info-circle-outlined'
              className='text-blue-link inline'
            />
          </Link>
        </div>
      </div>
      <div className='lg:hidden'>
        {NFTS.length > 0 ? (
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`${
                    open ? 'rounded-t' : 'rounded'
                  } flex justify-between items-center w-full p-3 rounded-t focus:outline-none bg-platinum text-sm`}
                >
                  <div className='gap-2 flex items-center'>
                    <span className='tracking-[0.2em] font-bold'>LANDS</span>
                    <span className='text-xs tracking-[0.2em] font-light'>
                      METAVERSE
                    </span>
                  </div>
                  <div className='flex items-center gap-2'>
                    <span className='text-xs font-semibold'>{NFTS.length}</span>
                    <Icon
                      icon='akar-icons:chevron-up'
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-4  text-charcoal`}
                    />
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel className='border-[0.5px] border-t-0 p-4 rounded-b border-platinum'>
                  <div className='grid grid-cols-4 gap-2'>
                    {NFTS.map((token) => (
                      <button
                        key={token.index}
                        className={`${
                          token.index === 1 && 'col-span-2 row-span-2'
                        } bg-[#F6F6F6] rounded object-cover aspect-square relative`}
                      >
                        {token.index === 5 && (
                          <div className='absolute bottom-0 right-0 rounded bg-[#ececec]/10 backdrop-blur-md z-10 h-[101%] w-full flex flex-col justify-center items-center gap-0.5'>
                            <Icon
                              icon='healthicons:ui-menu-grid'
                              color='white'
                              className='mt-1'
                            />
                            <span className='text-white text-[0.625rem] leading-3'>
                              View all
                            </span>
                          </div>
                        )}
                        <img
                          src={nftexample}
                          alt='nft example'
                          className='object-cover rounded aspect-square'
                        />
                      </button>
                    ))}
                  </div>
                  <Link
                    to='/learn'
                    className=' text-blue-link text-[0.625rem] leading-3 mt-3 w-full text-right flex items-center justify-end gap-1'
                  >
                    <span>
                      What’s a <span className='font-semibold'> Land?</span>{' '}
                    </span>
                    <Icon
                      icon='ant-design:info-circle-outlined'
                      className='text-blue-link inline'
                    />
                  </Link>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : (
          <button className='flex gap-1 justify-between items-center w-full p-3 rounded border-[0.5px] border-light-gray text-sm'>
            <div className='text-light-gray flex items-center gap-2 text-sm'>
              <span className='tracking-[0.2em] font-bold uppercase'>
                lands
              </span>
              <span className='font-light uppercase'>metaverse</span>
            </div>
            <Link
              to='/learn'
              className=' text-blue-link text-[0.625rem] leading-3 flex items-center gap-1'
            >
              <span>
                What’s a <span className='font-semibold'> Land?</span>{' '}
              </span>
              <Icon
                icon='ant-design:info-circle-outlined'
                className='text-blue-link inline'
              />
            </Link>
          </button>
        )}
      </div>
    </>
  );
};

export default LandsDisclosure;
