import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import ClipboardJS from 'clipboard';
import { ethers } from 'ethers';
import pohIcon from '../images/icons/poh-icon.svg';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';

import searchIllustration from '../images/illustrations/search.png';

import TokenDisclosure from '../components/TokenDisclosure.js';
import NFTSDisclosure from '../components/NFTSDisclosure.js';
import POAPDisclosure from '../components/POAPDisclosure.js';
import ENSDisclosure from '../components/ENSDisclosure.js';
import VoicesDisclosure from '../components/VoicesDisclosure.js';
import LandsDisclosure from '../components/LandsDisclosure.js';
import GamingDisclosure from '../components/GamingDisclosure.js';
import ReactGA from 'react-ga4';
import Avatar from 'boring-avatars';

const Search = () => {
  const provider = useOutletContext()[3];

  let [searchParams, setSearchParams] = useSearchParams();

  let navigate = useNavigate();
  const account = searchParams.get('account');
  const [userBalance, setUserBalance] = useState(0);
  const [verifiedHuman, setVerifiedHuman] = useState(false);
  const [ensName, setEnsName] = useState(null);
  const [copied, setCopied] = useState(false);
  let clipboard = new ClipboardJS('.group');

  const [valueSearch, setValueSearch] = useState('');
  const [address, setAddress] = useState(null);

  const verifyHuman = async (address) => {
    setVerifiedHuman(false);
    await fetch(`https://api.poh.dev/profiles/${address}`, {
      method: 'GET',
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then(
        (result) => result.status === 'REGISTERED' && setVerifiedHuman(true)
      )
      .catch((error) => {
        console.log(error);
      });
  };

  const getuserBalance = async (address) => {
    const balance = await provider.getBalance(address, 'latest');
    setUserBalance(ethers.utils.formatEther(balance));
  };

  const resolveName = async (name) => {
    return await provider.resolveName(name);
  };

  const resolveAddress = async (address) => {
    const ens = await provider.lookupAddress(address);
    setEnsName(ens);
  };

  const handleSubmit = async (event) => {
    ReactGA.event({
      category: 'Search',
      action: 'Search',
      label: valueSearch,
    });
    setEnsName(null);
    event.preventDefault();
    if (valueSearch.length === 42) {
      navigate(`/search?account=${valueSearch}`);
      setAddress(valueSearch);
      await verifyHuman(valueSearch);
      await getuserBalance(valueSearch);
      await resolveAddress(valueSearch);
    } else if (valueSearch.endsWith('.eth')) {
      navigate(`/search?account=${valueSearch}`);
      resolveName(valueSearch).then((data) => {
        setEnsName(valueSearch);
        setAddress(data);
        verifyHuman(data);
        getuserBalance(data);
      });
    } else {
      console.log('wrong address');
    }
  };

  useEffect(() => {
    let timeout;
    clipboard.on('success', function (e) {
      setCopied(true);
      e.clearSelection();
      timeout = setTimeout(() => {
        setCopied(false);
      }, 500);
    });

    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    const loadData = async (account) => {
      if (account === 42) {
        setAddress(account);
        await verifyHuman(account);
        await getuserBalance(account);
        await resolveAddress(account);
      } else if (account.endsWith('.eth')) {
        resolveName(account).then((data) => {
          setEnsName(account);
          setAddress(data);
          verifyHuman(data);
          getuserBalance(data);
        });
      } else {
        console.log('wrong address');
      }
    };
    if (account !== null) {
      setValueSearch(account);
      loadData(account);
    }
  }, [account]);

  return (
    <div className='m-5'>
      <div
        className={`${
          (address === null || address === '') &&
          'lg:flex lg:flex-col lg:h-full lg:justify-between'
        } lg:mx-8 lg:pt-[3.25rem] lg:pb-10`}
      >
        <form onSubmit={handleSubmit} className='flex w-full'>
          <label className='relative w-full block'>
            <input
              value={valueSearch}
              onChange={(e) => {
                setValueSearch(e.target.value);
              }}
              placeholder=' '
              className='w-full bg-transparent appearance-none py-3 px-4 rounded-l border-dark-platinum border-[0.5px]  focus:outline-none focus:ring-1 focus:ring-spreen-green  focus:border-transparent'
            />
            <span className='truncate w-fill absolute flex gap-1 items-center ml-4 mt-3  left-0 top-0 text-light-gray  cursor-text transition-transform search bg-transparent'>
              <Icon icon='mdi:account-search' className='text-[1.25rem]' />
              Search by Address or ENS
            </span>
          </label>
          <button
            type='submit'
            className='py-3 px-4 border border-spreen-green rounded-r bg-spreen-green'
          >
            <Icon
              icon='ant-design:search-outlined'
              className='text-white sm:hidden'
            />
            <span className='hidden sm:inline text-white font-semibold'>
              Search
            </span>
          </button>
        </form>
        {address !== null && address !== '' ? (
          <>
            <div className='flex gap-3 items-center justify-between mb-[1.875rem] mt-5'>
              <div className='gap-3 flex items-center'>
                <Avatar
                  size={76}
                  name={address}
                  variant='marble'
                  color={[
                    '#263449',
                    '#415398',
                    '#DF3CB1',
                    '#EBEBEB',
                    '#05F182',
                  ]}
                />

                <div className='flex flex-col'>
                  {verifiedHuman && (
                    <div className='w-fit border-[0.5px] border-[#FF9900] rounded-sm flex items-center gap-1 px-2 py-1'>
                      <img src={pohIcon} alt='poh icon' />
                      <span className='text-[0.6825rem] leading-[0.831875rem] bg-clip-text text-transparent bg-gradient-to-r from-[#FF9900] to-[#FFC700]'>
                        Verified Human
                      </span>
                    </div>
                  )}
                  <div className='flex items-center gap-2 '>
                    <span className='font-semibold text-[1.375rem] lg:text-2xl'>
                      {ensName !== null
                        ? ensName
                        : address.slice(0, 5) + '...' + address.slice(-4)}
                    </span>

                    <button data-clipboard-text={address} className='group'>
                      {!copied && (
                        <Icon
                          icon='mdi:content-copy'
                          className='text-dark-platinum group-hover:text-[#263449]'
                        />
                      )}
                      {copied && (
                        <Icon
                          icon='teenyicons:tick-circle-outline'
                          color='#263449'
                        />
                      )}
                    </button>

                    {/* CONFIGURATION USER NOT ENABLED YET */}
                    {/* <Icon
                  icon='mdi:account-cog'
                  color='#EBEBEB'
                  className='lg:hidden'
                /> */}
                  </div>
                  {/* BIO WHEN DB IS READY TO SAVE DATA FOR USER */}
                  {/* <span className='text-sm lg:text-base'>
                Artist based in Argentina 👾
              </span> */}
                </div>
              </div>
              <button className='cursor-not-allowed hidden lg:flex self-end px-2 py-1 border-[0.5px] border-light-gray rounded-sm items-center gap-1 text-light-gray text-xs'>
                Filter
                <Icon icon='charm:menu-kebab' color='#D6D6D6' />
              </button>
            </div>
            <div className='flex justify-end mt-4 lg:hidden'>
              <button className='cursor-not-allowed px-2 py-1 border-[0.5px] border-light-gray rounded-sm  flex items-center gap-1 text-light-gray text-xs'>
                Filter
                <Icon icon='charm:menu-kebab' color='#D6D6D6' />
              </button>
            </div>
            <div className='flex flex-col gap-3 mt-3 lg:mt-0 lg:grid lg:grid-cols-3'>
              <TokenDisclosure
                defaultAccount={address}
                userBalance={userBalance}
              />
              <NFTSDisclosure defaultAccount={address} />
              <POAPDisclosure defaultAccount={address} />
              <ENSDisclosure defaultAccount={address} />
              <VoicesDisclosure defaultAccount={address} />
              <LandsDisclosure />
              <GamingDisclosure />
            </div>
          </>
        ) : (
          <>
            <div className='flex flex-col items-center gap-3 max-w-xs lg:max-w-sm mx-auto mt-4 lg:mt-0'>
              <img src={searchIllustration} alt='search illustration' />
              <span className='font-medium text-sm text-dark-platinum'>
                Search by Address or ENS
              </span>
            </div>
            <div></div>
          </>
        )}
      </div>
    </div>
  );
};

export default Search;
