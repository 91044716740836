import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';

const PoapTokenId = () => {
  let location = useLocation();
  let tokenId = useParams();

  const [poapContent, setPoapContent] = useState();

  useEffect(() => {
    if (location.state !== null) {
      setPoapContent(location.state);
    }
    let requestOptionsGet = {
      method: 'GET',
      redirect: 'follow',
    };
    async function fetchPoapContent() {
      await fetch(`https://api.poap.xyz/token/${tokenId.id}`, requestOptionsGet)
        .then((response) => response.json())
        .then((result) => {
          setPoapContent(result);
        })
        .catch((error) => console.log('error', error));
    }
    fetchPoapContent();
  }, [tokenId.id, location.state]);

  return (
    <div className='m-5 '>
      <div className='lg:mx-8 lg:pt-[3.25rem] lg:pb-10'>
        {poapContent !== undefined && poapContent !== null && (
          <div className='flex flex-col gap-4 lg:flex-row lg:items-center lg:justify-around'>
            <div className='bg-[#F6F6F6] rounded-full aspect-square flex items-center justify-center max-w-[18.75rem] self-center'>
              <img
                src={poapContent.event.image_url}
                alt='nft example'
                className='object-cover rounded-full aspect-square'
              />
            </div>
            <div className='flex flex-col gap-4 lg:w-1/2'>
              <div className='flex flex-col mx-2 gap-2'>
                <span className='font-semibold text-silver lg:text-lg'>
                  #{tokenId.id}
                </span>
                <span className='font-semibold text-xl lg:font-bold lg:text-[2rem] lg:leading-[2.625rem]'>
                  {poapContent.event.name}
                </span>
                <span className='text-sm '>
                  {poapContent.event.description}
                </span>
              </div>
              <div className='mx-1 flex flex-col gap-1'>
                {poapContent.event.city !== undefined &&
                  poapContent.event.city !== '' && (
                    <span className='text-silver text-xs leading-3 flex items-center gap-1'>
                      <Icon
                        icon='mdi:map-marker'
                        className='inline'
                        height='24'
                        width='24'
                      />
                      {poapContent.event.city}
                    </span>
                  )}
                {poapContent.event.start_date !== undefined &&
                  poapContent.event.start_date !== '' && (
                    <span className='text-silver text-xs leading-3 flex items-center gap-1'>
                      <Icon
                        icon='mdi:calendar-blank'
                        className='inline'
                        height='24'
                        width='24'
                      />
                      {poapContent.event.start_date}
                    </span>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PoapTokenId;
