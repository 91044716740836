const Learn = () => {
  return (
    <>
      <div className='m-5 '>
        <h1 className='lg:mx-8 lg:pt-[3.25rem] text-5xl'>Coming soon.</h1>
      </div>
    </>
  );
};

export default Learn;
