import { Icon } from '@iconify/react';
import { Disclosure, Transition } from '@headlessui/react';
import { useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
const alchemyKey = process.env.REACT_APP_ALCHEMY_API_KEY;

const NFTSDisclosure = ({ defaultAccount }) => {
  const [NFTS, setNFTS] = useState([]);

  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    const removeNFTContract = [
      '0x22c1f6050e56d2876009903609a2cc3fef83b415',
      '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85',
    ];

    function removeNFTs(address) {
      return !removeNFTContract.includes(address);
    }

    async function fetchData() {
      await fetch(
        `https://eth-mainnet.g.alchemy.com/v2/${alchemyKey}/getNFTs/?owner=${defaultAccount}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setNFTS([]);
          const filteredArray = result.ownedNfts.filter((result) =>
            removeNFTs(result.contract.address)
          );
          filteredArray.map((result, index) =>
            fetch(
              `https://eth-mainnet.g.alchemy.com/v2/${alchemyKey}/getNFTMetadata?contractAddress=${result.contract.address}&tokenId=${result.id.tokenId}`,
              requestOptions
            )
              .then((response) => response.json())
              .then((result) => {
                if (result.metadata.image !== undefined) {
                  if (result.metadata.image.startsWith('ipfs://ipfs')) {
                    const newURL = result.metadata.image
                      .split('/')
                      .slice(2)
                      .join('/');
                    const resultObj = {
                      index: index,
                      contractAddress: result.contract.address,
                      tokenId: result.id.tokenId,
                      image: 'https://ipfs.io/'.concat(newURL),
                      name: result.metadata.name,
                      description: result.metadata.description,
                    };
                    setNFTS((element) => [...element, resultObj]);
                  } else if (result.metadata.image.startsWith('ipfs://')) {
                    const newURL = result.metadata.image
                      .split('/')
                      .slice(2)
                      .join('/');
                    const resultObj = {
                      index: index,
                      contractAddress: result.contract.address,
                      tokenId: result.id.tokenId,
                      image: 'https://ipfs.io/ipfs/'.concat(newURL),
                      name: result.metadata.name,
                      description: result.metadata.description,
                    };
                    setNFTS((element) => [...element, resultObj]);
                  } else {
                    const resultObj = {
                      index: index,
                      contractAddress: result.contract.address,
                      tokenId: result.id.tokenId,
                      image: result.metadata.image,
                      name: result.metadata.name,
                      description: result.metadata.description,
                    };
                    setNFTS((element) => [...element, resultObj]);
                  }
                } else if (result.metadata.image_url !== undefined) {
                  if (result.metadata.image_url.startsWith('ipfs://ipfs')) {
                    const newURL = result.metadata.image_url
                      .split('/')
                      .slice(2)
                      .join('/');
                    const resultObj = {
                      index: index,
                      contractAddress: result.contract.address,
                      tokenId: result.id.tokenId,
                      image: 'https://ipfs.io/'.concat(newURL),
                      name: result.metadata.name,
                      description: result.metadata.description,
                    };
                    setNFTS((element) => [...element, resultObj]);
                  } else if (result.metadata.image_url.startsWith('ipfs://')) {
                    const newURL = result.metadata.image_url
                      .split('/')
                      .slice(2)
                      .join('/');
                    const resultObj = {
                      index: index,
                      contractAddress: result.contract.address,
                      tokenId: result.id.tokenId,
                      image: 'https://ipfs.io/ipfs/'.concat(newURL),
                      name: result.metadata.name,
                      description: result.metadata.description,
                    };
                    setNFTS((element) => [...element, resultObj]);
                  } else {
                    const resultObj = {
                      index: index,
                      contractAddress: result.contract.address,
                      tokenId: result.id.tokenId,
                      image: result.metadata.image_url,
                      name: result.metadata.name,
                      description: result.metadata.description,
                    };
                    setNFTS((element) => [...element, resultObj]);
                  }
                }
              })
              .catch((error) => console.log('error', error))
          );
        })
        .catch((error) => console.log('error', error));
    }
    fetchData();
  }, [defaultAccount]);
  const [showincase, setshowincase] = useState([false, -1]);
  return (
    <>
      <div className='hidden lg:flex flex-col  col-span-2 rounded'>
        <div className='flex justify-between items-center bg-platinum rounded-t py-3 px-4'>
          <span className='tracking-[0.2em] font-bold'>NFTS</span>
          <span className='tracking-[0.2em] font-bold'>{NFTS.length}</span>
        </div>
        <div className='p-4 border-[0.5px] border-platinum rounded-b flex flex-col gap-3'>
          <div className='grid grid-cols-6 gap-2'>
            {NFTS.sort((a, b) => {
              return a.index - b.index;
            })
              .slice(0, 6)
              .map((token, index) => (
                <Link
                  to={
                    index !== 5
                      ? `/nft/${token.contractAddress}?tokenId=${token.tokenId}`
                      : `/nft?address=${defaultAccount}`
                  }
                  state={index !== 5 ? token : NFTS}
                  key={index}
                  className={`${
                    index < 2 && 'col-span-2 row-span-2'
                  } bg-[#F6F6F6] rounded object-cover aspect-square relative`}
                >
                  {index === 5 && (
                    <div className='absolute bottom-0 right-0 rounded bg-[#ececec]/10 backdrop-blur-md z-10 h-[101%] w-full flex flex-col justify-center items-center gap-0.5'>
                      <Icon
                        icon='healthicons:ui-menu-grid'
                        color='white'
                        className='mt-1'
                      />
                      <span className='text-white text-[0.625rem] leading-3'>
                        View all
                      </span>
                    </div>
                  )}
                  {showincase[0] && showincase[1] == index && index !== 5 && (
                    <div className='absolute bottom-0 right-0 rounded bg-[#ececec]/10 backdrop-blur-md z-10 h-[101%] w-full flex flex-col justify-center items-center gap-0.5'>
                      <span
                        className={`${
                          index >= 2 && 'text-[0.625rem] leading-3'
                        } text-white `}
                      >
                        Unable to display <br />
                        Click to see more
                      </span>
                    </div>
                  )}
                  <img
                    src={token.image}
                    onError={() => {
                      setshowincase([true, index]);
                    }}
                    alt={('nft', index)}
                    loading='lazy'
                    className='object-cover rounded aspect-square bg-hero-gradient'
                  />
                </Link>
              ))}
            {NFTS.length < 1 && (
              <div className='bg-[#F6F6F6] rounded object-cover aspect-square relative col-span-2 row-span-2'></div>
            )}
            {NFTS.length < 2 && (
              <div className='bg-[#F6F6F6] rounded object-cover aspect-square relative col-span-2 row-span-2'></div>
            )}
            {NFTS.length < 3 && (
              <div className='bg-[#F6F6F6] rounded object-cover aspect-square relative'></div>
            )}
            {NFTS.length < 4 && (
              <div className='bg-[#F6F6F6] rounded object-cover aspect-square relative'></div>
            )}
            {NFTS.length < 5 && (
              <div className='bg-[#F6F6F6] rounded object-cover aspect-square relative'></div>
            )}
            {NFTS.length < 6 && (
              <div className='bg-[#F6F6F6] rounded object-cover aspect-square relative'>
                {NFTS.length !== 0 && (
                  <Link
                    to={`/nft?address=${defaultAccount}`}
                    state={NFTS}
                    className='absolute bottom-0 right-0 rounded bg-[#ececec]/10 backdrop-blur-md z-10 h-[101%] w-full flex flex-col justify-center items-center gap-0.5'
                  >
                    <Icon
                      icon='healthicons:ui-menu-grid'
                      className='mt-1 text-charcoal'
                    />
                    <span className='text-charcoal text-[0.625rem] leading-3'>
                      View all
                    </span>
                  </Link>
                )}
              </div>
            )}
          </div>
          <Link
            to='/learn'
            className='text-blue-link text-[0.625rem] leading-3 w-full text-right flex items-center justify-end gap-1'
          >
            <span>
              What’s a <span className='font-semibold'> NFT?</span>{' '}
            </span>
            <Icon
              icon='ant-design:info-circle-outlined'
              className='text-blue-link inline'
            />
          </Link>
        </div>
      </div>
      <div className='lg:hidden'>
        {NFTS.length > 0 ? (
          <Disclosure defaultOpen='true'>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`${
                    open ? 'rounded-t' : 'rounded'
                  } flex justify-between items-center w-full p-3 rounded-t focus:outline-none bg-platinum text-sm`}
                >
                  <div className='gap-2 flex items-center'>
                    <span className='tracking-[0.2em] font-bold'>NFTS</span>
                  </div>
                  <div className='flex items-center gap-2'>
                    <span className='text-xs font-semibold'>{NFTS.length}</span>
                    <Icon
                      icon='akar-icons:chevron-up'
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-4  text-charcoal`}
                    />
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel className='border-[0.5px] border-t-0 p-4 rounded-b border-platinum'>
                  <div className='grid grid-cols-4 gap-2'>
                    {NFTS.sort((a, b) => {
                      return a.index - b.index;
                    })
                      .slice(0, 5)
                      .map((token, index) => (
                        <Link
                          to={
                            index !== 4
                              ? `/nft/${token.contractAddress}?tokenId=${token.tokenId}`
                              : `/nft?address=${defaultAccount}`
                          }
                          state={index !== 5 ? token : NFTS}
                          key={index}
                          className={`${
                            index === 0 && 'col-span-2 row-span-2'
                          } bg-[#F6F6F6] rounded object-cover aspect-square relative`}
                        >
                          {index === 4 && (
                            <div className='absolute bottom-0 right-0 rounded bg-[#ececec]/10 backdrop-blur-md z-10 h-[101%] w-full flex flex-col justify-center items-center gap-0.5'>
                              <Icon
                                icon='healthicons:ui-menu-grid'
                                color='white'
                                className='mt-1'
                              />
                              <span className='text-white text-[0.625rem] leading-3'>
                                View all
                              </span>
                            </div>
                          )}
                          <img
                            src={token.image}
                            alt='nft example'
                            className='object-cover rounded aspect-square'
                          />
                        </Link>
                      ))}
                  </div>
                  <Link
                    to='/learn'
                    className=' text-blue-link text-[0.625rem] leading-3 mt-3 w-full text-right flex items-center justify-end gap-1'
                  >
                    <span>
                      What’s an <span className='font-semibold'> NFT?</span>{' '}
                    </span>
                    <Icon
                      icon='ant-design:info-circle-outlined'
                      className='text-blue-link inline'
                    />
                  </Link>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : (
          <button className='flex gap-1 justify-between items-center w-full p-3 rounded border-[0.5px] border-light-gray text-sm'>
            <div className='text-light-gray flex items-center gap-2 text-sm'>
              <span className='tracking-[0.2em] font-bold uppercase'>NFTS</span>
            </div>
            <Link
              to='/learn'
              className=' text-blue-link text-[0.625rem] leading-3 flex items-center gap-1'
            >
              <span>
                What’s an <span className='font-semibold'> NFT?</span>
              </span>
              <Icon
                icon='ant-design:info-circle-outlined'
                className='text-blue-link inline'
              />
            </Link>
          </button>
        )}
      </div>
    </>
  );
};

export default NFTSDisclosure;
