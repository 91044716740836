import { useEffect, useState } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';

const Poap = () => {
  let location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  let defaultAccount = searchParams.get('address');
  const [poap, setPoap] = useState([]);

  useEffect(() => {
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    let graphql = JSON.stringify({
      query:
        'query ($address: ID!)\n{\n  accounts(where: {id: $address}) {\n    tokens {\n      id\n    }   \n    tokensOwned\n  }\n}',
      variables: {
        address: defaultAccount.toLowerCase(),
      },
    });

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
      redirect: 'follow',
    };

    let requestOptionsGet = {
      method: 'GET',
      redirect: 'follow',
    };

    if (location.state !== null) {
      setPoap(location.state);
    } else {
      async function fetchDataXDai() {
        await fetch(
          'https://api.thegraph.com/subgraphs/name/poap-xyz/poap-xdai',
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setPoap([]);
            result.data.accounts[0].tokens.map(({ id }) => {
              return fetch(
                `https://api.poap.xyz/token/${id}`,
                requestOptionsGet
              )
                .then((response) => response.json())
                .then((result) => {
                  setPoap((element) => [...element, result]);
                })
                .catch((error) => console.log('error', error));
            });
          })
          .catch((error) => console.log('error', error));
      }

      async function fetchDataMainnet() {
        await fetch(
          'https://api.thegraph.com/subgraphs/name/poap-xyz/poap',
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            result.data.accounts[0].tokens.map(({ id }) => {
              return fetch(
                `https://api.poap.xyz/token/${id}`,
                requestOptionsGet
              )
                .then((response) => response.json())
                .then((result) => {
                  setPoap((element) => [...element, result]);
                })
                .catch((error) => console.log('error', error));
            });
          })
          .catch((error) => console.log('error', error));
      }
      fetchDataXDai();
      fetchDataMainnet();
    }
  }, [defaultAccount, location.state]);

  return (
    <div className='m-5 '>
      <div className='lg:mx-8 lg:pt-[3.25rem] lg:pb-10'>
        {defaultAccount !== null && defaultAccount !== '' && (
          <>
            <div className='grid grid-cols-3 gap-2.5 sm:grid-cols-4 lg:grid-cols-5'>
              {poap !== null &&
                poap.map((event) => (
                  <Link
                    to={`/poap/${event.tokenId}`}
                    state={event}
                    key={event.event.id}
                    className='flex flex-col gap-1'
                  >
                    <div className='bg-[#F6F6F6] rounded-full object-cover aspect-square'>
                      <img
                        src={event.event.image_url}
                        alt='nft example'
                        className='object-cover rounded-full aspect-square'
                      />
                    </div>
                    <span className='text-xs font-semibold text-center truncate mx-1'>
                      {event.event.name}
                    </span>
                  </Link>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Poap;
