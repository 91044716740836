const NoMatch = () => {
  return (
    <div className='m-5 '>
      <h1 className='lg:mx-8 lg:pt-[3.25rem] text-5xl'>
        404: This page doesn't exist.
      </h1>
    </div>
  );
};

export default NoMatch;
