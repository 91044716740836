import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { Disclosure, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

const POAPDisclosure = ({ defaultAccount }) => {
  const [poap, setPoap] = useState([]);
  useEffect(() => {
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    let graphql = JSON.stringify({
      query:
        'query ($address: ID!)\n{\n  accounts(where: {id: $address}) {\n    tokens {\n      id\n    }   \n    tokensOwned\n  }\n}',
      variables: {
        address: defaultAccount.toLowerCase(),
      },
    });

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
      redirect: 'follow',
    };

    let requestOptionsGet = {
      method: 'GET',
      redirect: 'follow',
    };
    async function fetchDataXDai() {
      await fetch(
        'https://api.thegraph.com/subgraphs/name/poap-xyz/poap-xdai',
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setPoap([]);
          result.data.accounts[0].tokens.map(({ id }) => {
            fetch(`https://api.poap.xyz/token/${id}`, requestOptionsGet)
              .then((response) => response.json())
              .then((result) => {
                setPoap((element) => [...element, result]);
              })
              .catch((error) => console.log('error', error));
          });
        })
        .catch((error) => console.log('error', error));
    }

    async function fetchDataMainnet() {
      await fetch(
        'https://api.thegraph.com/subgraphs/name/poap-xyz/poap',
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          result.data.accounts[0].tokens.map(({ id }) => {
            fetch(`https://api.poap.xyz/token/${id}`, requestOptionsGet)
              .then((response) => response.json())
              .then((result) => {
                setPoap((element) => [...element, result]);
              })
              .catch((error) => console.log('error', error));
          });
        })
        .catch((error) => console.log('error', error));
    }
    fetchDataXDai();
    fetchDataMainnet();
  }, [defaultAccount]);

  return (
    <>
      <div className='hidden lg:flex flex-col  col-span-1 rounded'>
        <div className='flex justify-between items-center bg-platinum rounded-t py-3 px-4'>
          <div className='flex gap-2 items-center'>
            <span className='tracking-[0.2em] font-bold uppercase'>POAPS</span>
            <span className='text-xs tracking-[0.2em] font-light uppercase'>
              Collected
            </span>
          </div>
          <span className='tracking-[0.2em] font-bold'>{poap.length}</span>
        </div>
        <div className='p-4 border-[0.5px] border-platinum rounded-b flex flex-col justify-between gap-3 h-fill'>
          <div className='grid grid-cols-3 gap-2'>
            {poap.slice(0, 6).map((event, index) => (
              <Link
                to={
                  index !== 5
                    ? `/poap/${event.tokenId}`
                    : `/poap?address=${defaultAccount}`
                }
                state={index !== 5 ? event : poap}
                key={event.event.id}
                className='bg-[#F6F6F6] rounded-full object-cover aspect-square relative'
              >
                {index === 5 && (
                  <button className='absolute bottom-0 right-0 rounded-full bg-[#ececec]/10 backdrop-blur-md z-10 h-[103%] w-full flex flex-col justify-center items-center gap-0.5'>
                    <Icon
                      icon='healthicons:ui-menu-grid'
                      color='white'
                      className='mt-1'
                    />
                    <span className='text-white text-[0.625rem] leading-3'>
                      View all
                    </span>
                  </button>
                )}
                <img
                  src={event.event.image_url}
                  alt='nft example'
                  className='object-cover rounded-full aspect-square'
                />
              </Link>
            ))}
            {poap.length < 1 && (
              <div className='bg-[#F6F6F6] rounded-full object-cover aspect-square'></div>
            )}
            {poap.length < 2 && (
              <div className='bg-[#F6F6F6] rounded-full object-cover aspect-square'></div>
            )}
            {poap.length < 3 && (
              <div className='bg-[#F6F6F6] rounded-full object-cover aspect-square'></div>
            )}
            {poap.length < 4 && (
              <div className='bg-[#F6F6F6] rounded-full object-cover aspect-square'></div>
            )}
            {poap.length < 5 && (
              <div className='bg-[#F6F6F6] rounded-full object-cover aspect-square'></div>
            )}
            {poap.length < 6 && (
              <div className='bg-[#F6F6F6] rounded-full object-cover aspect-square relative'>
                {poap.length !== 0 && (
                  <Link
                    to={`/poap?address=${defaultAccount}`}
                    state={poap}
                    className='absolute bottom-0 right-0 rounded-full bg-[#ececec]/10 backdrop-blur-md z-10 h-[103%] w-full flex flex-col justify-center items-center gap-0.5'
                  >
                    <Icon
                      icon='healthicons:ui-menu-grid'
                      className='mt-1 text-charcoal'
                    />
                    <span className='text-charcoal text-[0.625rem] leading-3'>
                      View all
                    </span>
                  </Link>
                )}
              </div>
            )}
          </div>
          <Link
            to='/learn'
            className='text-blue-link text-[0.625rem] leading-3 w-full text-right flex items-center justify-end gap-1'
          >
            <span>
              What’s a <span className='font-semibold'> POAP?</span>{' '}
            </span>
            <Icon
              icon='ant-design:info-circle-outlined'
              className='text-blue-link inline'
            />
          </Link>
        </div>
      </div>

      <div className='lg:hidden'>
        {poap.length > 0 ? (
          <Disclosure defaultOpen='true'>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`${
                    open ? 'rounded-t' : 'rounded'
                  } flex justify-between items-center w-full p-3 rounded-t focus:outline-none bg-platinum text-sm`}
                >
                  <div className='gap-2 flex items-center'>
                    <span className='tracking-[0.2em] font-bold'>POAPS</span>
                    <span className='text-xs tracking-[0.2em] font-light'>
                      COLLECTED
                    </span>
                  </div>
                  <div className='flex items-center gap-2'>
                    <span className='text-xs font-semibold'>{poap.length}</span>
                    <Icon
                      icon='akar-icons:chevron-up'
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-4  text-charcoal`}
                    />
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel className='border-[0.5px] border-t-0 p-4 rounded-b border-platinum'>
                  <div className='grid grid-cols-3 gap-2'>
                    {poap.slice(0, 6).map((event, index) => (
                      <Link
                        to={
                          index !== 5
                            ? `/poap/${event.tokenId}`
                            : `/poap?address=${defaultAccount}`
                        }
                        state={index !== 5 ? event : poap}
                        key={event.event.id}
                        className='bg-[#F6F6F6] rounded-full object-cover aspect-square relative'
                      >
                        {index === 5 && (
                          <button
                            // to={`/poap?address=${defaultAccount}`}
                            className='absolute bottom-0 right-0 rounded-full bg-[#ececec]/10 backdrop-blur-md z-10 h-[101%] w-full flex flex-col justify-center items-center gap-0.5'
                          >
                            <Icon
                              icon='healthicons:ui-menu-grid'
                              color='white'
                              className='mt-1'
                            />
                            <span className='text-white text-[0.625rem] leading-3'>
                              View all
                            </span>
                          </button>
                        )}
                        <img
                          src={event.event.image_url}
                          alt='nft example'
                          className='object-cover rounded-full aspect-square'
                        />
                      </Link>
                    ))}
                  </div>
                  <Link
                    to='/learn'
                    className=' text-blue-link text-[0.625rem] leading-3 mt-3 w-full text-right flex items-center justify-end gap-1'
                  >
                    <span>
                      What’s a <span className='font-semibold'> POAP?</span>{' '}
                    </span>
                    <Icon
                      icon='ant-design:info-circle-outlined'
                      className='text-blue-link inline'
                    />
                  </Link>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : (
          <button className='flex gap-1 justify-between items-center w-full p-3 rounded border-[0.5px] border-light-gray text-sm'>
            <div className='text-light-gray flex items-center gap-2 text-sm'>
              <span className='tracking-[0.2em] font-bold uppercase'>
                POAPS
              </span>
              <span className='font-light'>COLLECTED</span>
            </div>
            <Link
              to='/learn'
              className=' text-blue-link text-[0.625rem] leading-3 flex items-center gap-1'
            >
              <span>
                What’s a <span className='font-semibold'> POAP?</span>
              </span>
              <Icon
                icon='ant-design:info-circle-outlined'
                className='text-blue-link inline'
              />
            </Link>
          </button>
        )}
      </div>
    </>
  );
};

export default POAPDisclosure;
