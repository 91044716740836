import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './pages/Home';
import NoMatch from './pages/NoMatch';
import Search from './pages/Search';
import Poap from './pages/Poap';
import PoapTokenId from './pages/PoapTokenId';
import Nft from './pages/Nft';
import NftTokenId from './pages/NftTokenId';
import Ens from './pages/Ens';
import Favorites from './pages/Favorites';
import Voices from './pages/Voices';
import Learn from './pages/Learn';
import Voicesnfts from './pages/Voicesnfts';
import VoicesTokenId from './pages/VoicesTokenId';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />}>
        <Route index element={<Home />} />
        <Route path='/search' element={<Search />} />
        <Route path='/poap' element={<Poap />} />
        <Route path='/poap/:id' element={<PoapTokenId />} />
        <Route path='/nft' element={<Nft />} />
        <Route path='/nft/:id' element={<NftTokenId />} />
        <Route path='/ens' element={<Ens />} />
        <Route path='/voicesnft' element={<Voicesnfts />} />
        <Route path='/voicesnft/:id' element={<VoicesTokenId />} />
        <Route path='/voices' element={<Voices />} />
        <Route path='/favorites' element={<Favorites />} />
        <Route path='/learn' element={<Learn />} />
        <Route path='*' element={<NoMatch />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
