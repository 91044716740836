import logo from '../images/brand/logo-black.svg';
import { Icon } from '@iconify/react';
import ClipboardJS from 'clipboard';
import isologotiposb from '../images/brand/isologotipo-sidebar.svg';
import logosb from '../images/brand/logo-bottom-nav.svg';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Navbar = ({ defaultAccount, ensName, connect }) => {
  const [copied, setCopied] = useState(false);
  let clipboard = new ClipboardJS('.group');
  let [searchParams, setSearchParams] = useSearchParams();
  const isConnected = searchParams.get('connect');

  useEffect(() => {
    if (isConnected === 'true') {
      connect();
    }
  }, [isConnected, connect]);

  useEffect(() => {
    let timeout;
    clipboard.on('success', function (e) {
      setCopied(true);
      e.clearSelection();
      timeout = setTimeout(() => {
        setCopied(false);
      }, 500);
    });

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <>
      <header className='bg-dark-platinum flex justify-between items-center px-5 py-5 lg:hidden'>
        <Link to='/'>
          <img src={logo} alt='voicemix' />
        </Link>
        <button
          onClick={defaultAccount === '' ? connect : undefined}
          data-clipboard-text={defaultAccount}
          className={`${
            defaultAccount !== ''
              ? 'bg-transparent border-charcoal text-charcoal'
              : 'bg-btn-gradient text-white border-transparent'
          } group py-2 px-3  rounded-sm border-[0.5px]  flex items-center gap-1 text-xs leading-3 font-medium  justify-center`}
        >
          {defaultAccount !== '' && defaultAccount !== null
            ? ensName !== null
              ? ensName
              : defaultAccount.slice(0, 5) + '...' + defaultAccount.slice(-4)
            : 'Connect'}

          {!copied && (
            <>
              <Icon
                icon='cib:ethereum'
                color={`${defaultAccount !== '' ? '#263449' : '#FFFFFF'}`}
                className={`${defaultAccount !== '' && 'group-hover:hidden'}`}
              />
              <Icon
                icon='akar-icons:copy'
                color='#263449'
                className={`${
                  defaultAccount !== '' && 'group-hover:inline-flex'
                } hidden`}
              />
            </>
          )}
          {copied && (
            <Icon icon='teenyicons:tick-circle-outline' color='#263449' />
          )}
        </button>
      </header>
      <header className='hidden lg:flex flex-col min-h-screen h-full w-[160px] z-10 top-0 left-0 border-r-[0.5px] border-dark-platinum  overflow-x-hidden'>
        <div className='flex flex-col justify-between h-screen fixed w-auto'>
          <Link to='/' className='my-8'>
            <img
              src={isologotiposb}
              alt='isologotipo voicemix'
              className='mx-auto h-[16px]'
            />
          </Link>
          <nav className='flex flex-col justify-between p-5 flex-1'>
            <div className='flex flex-col'>
              <NavLink
                to='/'
                className={({ isActive }) =>
                  `${
                    isActive ? 'opacity-100' : 'opacity-20'
                  } flex items-center text-[0.625rem] leading-3 font-medium gap-1 pt-3 pb-2`
                }
              >
                <Icon icon='mdi:account' color='#263449' width='24' />
                Profile
              </NavLink>
              <NavLink
                to='/search'
                className={({ isActive }) =>
                  `${
                    isActive ? 'opacity-100' : 'opacity-20'
                  } flex items-center text-[0.625rem] leading-3 font-medium gap-1 pt-3 pb-2`
                }
              >
                <Icon icon='mdi:account-search' color='#263449' width='24' />
                Search
              </NavLink>
              <NavLink
                to='/favorites'
                className={({ isActive }) =>
                  `${
                    isActive ? 'opacity-100' : 'opacity-20'
                  } flex items-center text-[0.625rem] leading-3 font-medium gap-1 pt-3 pb-2`
                }
              >
                <Icon icon='mdi:heart' color='#263449' width='24' />
                Favorites
              </NavLink>
              <div className='w-full h-[1px] bg-[#D6D6D6]'></div>
              <NavLink
                to='/voices'
                className={({ isActive }) =>
                  `${
                    isActive ? 'opacity-100' : 'opacity-20'
                  } flex items-center text-[0.625rem] leading-3 font-medium gap-1 pt-3 pb-2`
                }
              >
                <img src={logosb} alt='logo' className='w-6' />
                Voices
              </NavLink>
            </div>
            <div className='flex flex-col'>
              {/* <NavLink
                to='profileEdit'
                className='flex items-center gap-1 border-b-[0.5px] border-light-gray py-5'
              >
                <Icon icon='mdi:account-cog' color='#D6D6D6' />
                <span className='text-xs text-light-gray leading-3 font-medium'>
                  Edit profile
                </span>
              </NavLink> */}
              <button
                onClick={defaultAccount === '' ? connect : undefined}
                data-clipboard-text={defaultAccount}
                className={`${
                  defaultAccount !== ''
                    ? 'bg-transparent border-charcoal text-charcoal'
                    : 'bg-btn-gradient text-white border-transparent'
                } group py-2 px-3  rounded-sm border-[0.5px]  flex items-center gap-1 text-xs leading-3 font-medium  justify-center mt-5 w-full`}
              >
                {defaultAccount !== '' && defaultAccount !== null
                  ? ensName !== null
                    ? ensName
                    : defaultAccount.slice(0, 5) +
                      '...' +
                      defaultAccount.slice(-4)
                  : 'Connect'}

                {!copied && (
                  <>
                    <Icon
                      icon='cib:ethereum'
                      color={`${defaultAccount !== '' ? '#263449' : '#FFFFFF'}`}
                      className={`${
                        defaultAccount !== '' && 'group-hover:hidden'
                      }`}
                    />
                    <Icon
                      icon='akar-icons:copy'
                      color='#263449'
                      className={`${
                        defaultAccount !== '' && 'group-hover:inline-flex'
                      } hidden`}
                    />
                  </>
                )}
                {copied && (
                  <Icon icon='teenyicons:tick-circle-outline' color='#263449' />
                )}
              </button>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Navbar;
